<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createInterpratorCompany"
        v-if="hasPermission('create_interprator_company')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="interpratorCompanys"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_interprator_company')"
        :per-page="0"
      >
        <template #custom-foot>
          <b-tr variant="light">
            <b-td :colspan="fields.length">
              <span class="font-weight-bolder">
                Total Rows: {{ totalItems }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <template #cell(serial)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #cell(name)="row">
        {{ truncate(row.item.name, 20) }} 
      </template>
        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{
            row.item.created_by}}
        </template>
        <template #cell(updated_by_data)="row">
          {{
            row.item.updated_by
          }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editInterpratorCompany(row.item)"
            v-if="hasPermission('update_interprator_company')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeInterpratorCompany(row.item)"
            v-if="hasPermission('delete_interprator_company')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
      <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
    </b-card>
    <interprator-company-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${interpratorCompanyCreateModalCount}`"
    />
    <interprator-company-edit-modal
      :interpratorCompany="interpratorCompany"
      @modalClosed="onModalClosed"
      :key="`edit-${interpratorCompanyEditModalCount}`"
    />
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import InterpratorCompanyCreateModal from "@/components/admin/interpratorCompany/InterpratorCompanyCreateModal.vue";
import InterpratorCompanyEditModal from "@/components/admin/interpratorCompany/InterpratorCompanyEditModal.vue";

export default {
  components: {
    InterpratorCompanyCreateModal, 
    InterpratorCompanyEditModal
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Created At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      interpratorCompanys: [],
      interpratorCompany: null,
      InterpratorCompanyCreateModal: 0,
      InterpratorCompanyEditModal: 0,
      interpratorCompanyCreateModalCount: 0,
      interpratorCompanyEditModalCount: 0,
      nameFilter: "",
    };
  },
  mixins: [util],
  async mounted() {
    await this.fetchPaginatedData();
  },
  methods: {
    ...mapActions({
      getInterpratorCompany: "appData/getInterpratorCompany",
      deleteInterpratorCompany: "appData/deleteInterpratorCompany",
    }),
    async filter() {
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
        };
        if (this.nameFilter) {
          tempParams["name"] = this.nameFilter;
        }
        const res = await this.getInterpratorCompany(tempParams);
        this.interpratorCompanys = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createInterpratorCompany() {
      this.interpratorCompanyCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("interprator-company-create-modal");
      });
    },
    editInterpratorCompany(interpratorCompany) {
      this.interpratorCompany = interpratorCompany;
      this.interpratorCompanyEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("interprator-company-edit-modal");
      });
    },
    async removeInterpratorCompany(interpratorCompany) {
      this.interpratorCompany = interpratorCompany;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteInterpratorCompany({
              pk: this.interpratorCompany.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "InterpratorCompany deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.interpratorCompanys.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
};
</script>
  
<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
  
  